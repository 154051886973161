<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <v-row v-if="getInstructionsListLoading">
      <v-progress-circular
        class="mx-auto"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <v-data-table
      v-else
      :headers="headers"
      :items="!!instructionsList ? instructionsList : []"
      item-key="name"
      class="elevation-3"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="orderDetails(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>Order Details</span>
        </v-tooltip>
        <!-- <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon> -->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
const headers = [
  { text: 'Id', value: 'ID', sortable: false },
  {
    text: 'Port of Loading',
    value: 'portsOfLoading.port',
    sortable: false
  },
  {
    text: 'Port of Discharge',
    value: 'portsOfDischarge.port',
    sortable: false
  },
  {
    text: 'Status',
    value: 'status',
    sortable: false
  },
  { text: 'Actions', value: 'actions', sortable: false }
];

export default {
  name: 'InstructionsList',

  data: () => ({
    headers,
    instructionsList: []
  }),
  async mounted() {
    //this.loadInstructionsList() returns array of instrictions or null
    this.instructionsList = await this.loadInstructionsList();
    if (this.instructionsList) {
      this.instructionsList.forEach((item) => {
        item.portsOfDischarge
          ? (item.portsOfDischarge = JSON.parse(item.portsOfDischarge))
          : (item.portsOfDischarge = { port: 'no data' });
        item.portsOfLoading
          ? (item.portsOfLoading = JSON.parse(item.portsOfLoading))
          : (item.portsOfLoading = { port: 'no data' });
      });
    }
  },
  methods: {
    ...mapActions({
      loadInstructionsList: 'instructionsList/loadInstructionsList'
    }),
    orderDetails(item) {
      this.$router.push(`/order-details/${item.ID}`);
    }
    // deleteItem(item) {
    //   console.log('>>>', item.id, 'DELETE id  <<<');
    // }
  },
  computed: {
    ...mapGetters('instructionsList', ['getInstructionsListLoading'])
  }
};
</script>
