<template>
  <v-container>
    <div class="text-center">
      <h1>All Instructions</h1>
    </div>

    <InstructionsList />
  </v-container>
</template>

<script>
import InstructionsList from '../components/InstructionsList';

export default {
  name: 'AllInstructions',
  components: { InstructionsList }
};
</script>
